@import '@/styles/mixins';

.button {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
}
.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    @include screen('desktop') {
        font-size: 18px;
    }
}
